import AcceptDialog from './AcceptDialog.vue'
import DeleteDialog from './DeleteDialog.vue'
import EventCard from './EventCard.vue'
import InfoCard from './InfoCard.vue'
import RejectDialog from './RejectDialog.vue'

export default {
  AcceptDialog,
  DeleteDialog,
  EventCard,
  InfoCard,
  RejectDialog,
}
