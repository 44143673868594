import { render, staticRenderFns } from "./University.vue?vue&type=template&id=0bc5e3ca&scoped=true&"
import script from "./University.vue?vue&type=script&lang=js&"
export * from "./University.vue?vue&type=script&lang=js&"
import style0 from "./University.vue?vue&type=style&index=0&id=0bc5e3ca&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.0_5mtat6ibfcpfi5czj7zg2dyadi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bc5e3ca",
  null
  
)

export default component.exports