import { render, staticRenderFns } from "./Documents.vue?vue&type=template&id=0e26b15c&scoped=true&"
import script from "./Documents.vue?vue&type=script&lang=js&"
export * from "./Documents.vue?vue&type=script&lang=js&"
import style0 from "./Documents.vue?vue&type=style&index=0&id=0e26b15c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.0_5mtat6ibfcpfi5czj7zg2dyadi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e26b15c",
  null
  
)

export default component.exports