import { render, staticRenderFns } from "./PhotoUpload.vue?vue&type=template&id=99b42430&scoped=true&"
import script from "./photoUpload.comp.js?vue&type=script&lang=js&"
export * from "./photoUpload.comp.js?vue&type=script&lang=js&"
import style0 from "./PhotoUpload.vue?vue&type=style&index=0&id=99b42430&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.0_5mtat6ibfcpfi5czj7zg2dyadi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99b42430",
  null
  
)

export default component.exports