// TODO make a snippet for this
export default {
  props: {
    disabled: { default: false },
    model: { type: Object, required: true },
    name: { type: String, required: true },
    rules: { required: true },
    type: { type: String, required: true },
  },
  data() {
    return {}
  },
}
