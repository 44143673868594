import { render, staticRenderFns } from "./ProfilePhoto.vue?vue&type=template&id=5c727822&"
import script from "@/views/userEdit/profilePhoto.comp.js?vue&type=script&lang=js&"
export * from "@/views/userEdit/profilePhoto.comp.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.0_5mtat6ibfcpfi5czj7zg2dyadi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports